body {
	font-family: Raleway, sans-serif;
	color: #2e2b2b;
	font-size: 1rem;
	line-height: 1.6rem;
}

h1 {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	color: #0a706b;
	font-size: 2.571rem;
	line-height: 3.271rem;
	font-weight: 500;
}

h2 {
	margin-top: 1.5rem;
	margin-bottom: 0.7rem;
	color: #0a706b;
	font-size: 1.714rem;
	line-height: 2.414rem;
	font-weight: 600;
}

h3 {
	margin-top: 1.5rem;
	margin-bottom: 0.7rem;
	color: #0a706b;
	font-size: 1.5rem;
	line-height: 2.2rem;
	font-weight: 600;
}

h4 {
	margin-top: 1.25rem;
	margin-bottom: 0.7rem;
	color: #0a706b;
	font-size: 1.286rem;
	line-height: 1.986rem;
	font-weight: 600;
}

h5 {
	margin-top: 1.25rem;
	margin-bottom: 0.7rem;
	color: #0a706b;
	font-size: 1.143rem;
	line-height: 1.843rem;
	font-weight: 600;
}

h6 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	color: #0a706b;
	font-size: 1rem;
	line-height: 1.7rem;
	font-weight: 600;
	text-transform: uppercase;
}

p {
	margin-bottom: 1rem;
	line-height: 1.7rem;
}

a {
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	text-decoration: underline;
}

ul {
	margin-top: 0px;
	margin-bottom: 1rem;
	padding-left: 2.5rem;
}

ol {
	margin-top: 0px;
	margin-bottom: 10px;
	padding-left: 2.5rem;
}

label {
	display: block;
	margin-bottom: 5px;
	font-size: 0.8571rem;
	line-height: 1.5rem;
	font-weight: 700;
}

blockquote {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	padding: 0.75rem 1.205rem 0.75rem 1.25rem;
	border-left: 5px solid #b22b25;
	background-color: #f4f4f4;
	color: #01579b;
	font-size: 1.143rem;
	line-height: 1.843rem;
	font-weight: 600;
}

.button {
	display: inline-block;
	height: 44px;
	min-width: 100px;
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 1rem;
	padding: 9px 16px;
	border-radius: 2px;
	background-color: #005ea8;
	color: #fff;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
}

.button:hover {
	background-color: #0079d8;
	background-image: none;
}

.button:active {
	background-image: none;
	box-shadow: inset 0 3px 10px 0 rgba(0, 0, 0, 0.2);
}

.button.secondary {
	border: 1px none #000;
	background-color: #003966;
	background-image: none;
	color: #fff;
	text-align: center;
}

.button.secondary:hover {
	background-color: #0064b3;
}

.main-content {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.main-content h1 {
	color: #01579b;
}

.button-styles {
	padding-top: 0rem;
	padding-bottom: 0rem;
}

.form-styles {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.container {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
}

.container.content-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1300px;
	min-height: 500px;
	padding: 1.25rem 3vw 3rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #fff;
}

.container.brand-container {
	display: block;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.container.nav-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 40px;
	float: none;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-bottom: 0;
	padding-top: 0;
	list-style-type: none;
}

.container.home-content-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 3rem;
	padding-left: 3rem;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.container.signoff-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0rem;
	padding: 1rem 0rem 1.5rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px solid hsla(0, 0%, 100%, 0.15);
}

.container.top-nav-container {
	overflow: hidden;
	width: 100%;
	max-height: 50px;
	max-width: none;
	padding-right: 3rem;
	padding-left: 3rem;
	background-color: #17488d;
	opacity: 1;
	-webkit-transition: all 500ms ease-in-out;
	transition: all 500ms ease-in-out;
}

.container.footer-content-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.container.events-container {
	max-width: none;
	padding-right: 3rem;
	padding-left: 3rem;
}

.container.cta-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: none;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.left-nav-col {
	width: 20%;
	min-width: 180px;
	margin-top: -2px;
	margin-right: 2rem;
	padding-top: 0px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.content-col {
	width: 100%;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.content-col.no-left-right-columns {
	max-width: 1024px;
}

.breadcrumbs-wrapper {
	margin-top: 5px;
	font-size: 0.78rem;
	line-height: 1rem;
	font-weight: 400;
	text-transform: uppercase;
}

.breadcrumb-link {
	margin-right: 0.5rem;
	padding-right: 0.5rem;
	border-right: 1px solid #bdbdbd;
	color: #424242;
	font-weight: 400;
	text-decoration: none;
}

.breadcrumb-link:hover {
	color: #69b5a4;
}

.breadcrumb-current {
	display: inline-block;
	color: #005ea8;
}

.content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.right-side-col {
	width: 20%;
	max-width: 200px;
	min-width: 175px;
	margin-left: 2rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.right-side-col h2 {
	margin-top: 0.25rem;
	margin-bottom: 0.5rem;
	color: #0a0406;
	font-size: 1.143rem;
	line-height: 1.743rem;
}

.right-side-col p {
	color: #0a0406;
	font-size: 0.9rem;
	line-height: 1.4rem;
	font-weight: 400;
}

.right-side-col h3 {
	margin-top: 0.25rem;
	margin-bottom: 0.5rem;
	color: #2d3047;
	font-size: 1.143rem;
	line-height: 1.743rem;
}

.right-side-col h4 {
	margin-top: 0.25rem;
	margin-bottom: 0.5rem;
	color: #2d3047;
	font-size: 1.143rem;
	line-height: 1.743rem;
}

.left-nav-section-title {
	margin-top: 0rem;
	font-size: 1rem;
	text-decoration: none;
	text-transform: uppercase;
}

.left-nav-section-title-link,
.left-nav-section-title-link:hover {
	display: block;
	color: #2d3047;
	font-size: 1.143rem;
	line-height: 1.843rem;
	font-weight: 700;
	letter-spacing: 0.02rem;
	text-decoration: none;
	border-bottom: 0px;
	padding-bottom: 0px;
}

.left-nav-link {
	display: block;
	padding: 0.6rem 0.75rem;
	background-image: url('/20181016082423/assets/images/right-green-arrow-02.svg');
	background-position: -10px 50%;
	background-size: 13px 13px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #424242;
	font-size: 1rem;
	line-height: 1.4rem;
	font-weight: 400;
	text-decoration: none;
}

.left-nav-link:hover {
	display: block;
	margin-left: 0px;
	padding-right: 0.5rem;
	padding-left: 1rem;
	background-image: url('/20181016082423/assets/images/right-blue-arrow.svg');
	background-position: 0px 50%;
	color: #005ea8;
}

.left-nav-link.w--current {
	margin-left: 0rem;
	padding-right: 0.5rem;
	padding-left: 1rem;
	background-image: url('/20181016082423/assets/images/right-blue-arrow.svg');
	background-position: 0px 50%;
	color: #005ea8;
}

.left-nav-link.grandchild-link {
	background-size: 11px 11px;
	font-size: 0.9rem;
	line-height: 1.3rem;
}

.left-nav-link.grandchild-link.w--current {
	margin-left: 0rem;
	padding-right: 0.5rem;
	padding-left: 1rem;
}

.left-nav-link.greatgrandchild-link {
	background-size: 9px 9px;
	font-size: 0.83rem;
	line-height: 1.23rem;
}

.left-nav-link.greatgrandchild-link.w--current {
	padding-right: 0.5rem;
	padding-left: 1rem;
	font-size: 0.83rem;
	line-height: 1.23rem;
}

.header-section {
	position: relative;
	z-index: 5;
	width: 100%;
	border-bottom: 4px none #17488d;
	background-color: #005ea8;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
}

.navbar {
	padding: 0.125rem 3rem;
	background-color: transparent;
}

.logo {
	width: 100%;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.brand {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 250px;
	height: 105px;
	margin-right: 1rem;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.brand.w--current,
.brand.active {
	width: 200px;
	margin-right: 2rem;
}

.nav-menu {
	display: block;
	margin-left: 2rem;
	float: right;
}

.main-nav-link {
	position: relative;
	display: block;
	margin-right: 2px;
	margin-left: 2px;
	padding: 0.6rem 0.75rem;
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: #fff;
	font-size: 1rem;
	line-height: 1.7rem;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
}

.main-nav-link:hover {
	box-shadow: inset 0 3px 0 0 #e51837;
	color: #fff;
}

.main-nav-link.active,
.main-nav-link.w--current {
	box-shadow: inset 0 3px 0 0 #e51837;
	color: #fff;
}

.main-nav-link.mobile-duplicate {
	display: none;
}

.dropdown-wrapper {
	position: relative;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	text-align: center;
}

.dropdown-list {
	position: absolute;
	overflow: hidden;
	height: 0px;
	max-width: 200px;
	min-width: 200px;
	margin-left: 0.75rem;
	padding: 0.5rem;
	background-color: #17488d;
	opacity: 0;
	text-align: left;
}

.dropdown-link {
	display: block;
	margin-top: 2px;
	margin-bottom: 2px;
	padding: 0.5rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.3rem;
	font-weight: 400;
	text-decoration: none;
}

.dropdown-link:hover {
	background-color: #223a66;
	color: #fff;
	font-weight: 400;
}

.dropdown-link.w--current {
	background-color: #fff;
	color: #0a706b;
}

.rotator {
	height: 650px;
	background-color: #e2e2e2;
}

.slideshow-arrow {
	position: absolute;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.slideshow-arrow:hover {
	opacity: 0.6;
}

.slidenav {
	opacity: 0.6;
	font-size: 0.75rem;
}

.slide-caption-wrapper {
	position: absolute;
	left: 0px;
	top: 40%;
	right: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 40%;
	margin-right: 0rem;
	margin-left: 4rem;
	padding: 0.5rem 1rem 1rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-transform: translate(0px, -50%);
	-ms-transform: translate(0px, -50%);
	transform: translate(0px, -50%);
}

.slide-caption-header {
	margin-top: 0.5rem;
	color: #fff;
	font-size: 2.571rem;
	line-height: 3.171rem;
	font-weight: 300;
	text-transform: capitalize;
}

.slide-caption-paragraph {
	margin-top: 1rem;
	color: #fff;
}

.slide-wrap {
	background-color: #fff;
}

.home-content-section {
	display: block;
	padding-top: 5rem;
	padding-bottom: 5rem;
	background-color: #f8f8f8;
}

.home-content-section.home-content-section-pattern-bg {
	display: none;
	padding-top: 6rem;
	padding-bottom: 4rem;
	border-top: 2px solid #e2e2e2;
	background-image: url('/20181016082423/assets/images/square-pattern.png');
	background-size: 150px;
	background-attachment: fixed;
}

.home-content-section.cta-section {
	padding-top: 0rem;
	padding-bottom: 0rem;
	border-top: 1px solid #f4f4f4;
	background-color: #fff;
	background-image: url('/20181016082423/assets/images/ama-pattern-sprinkles-bone50-01.png');
	background-position: 0px 0px;
	background-size: 368px;
	background-attachment: fixed;
}

.home-content-section.two-cb-home-content-section {
	display: none;
	padding-bottom: 4rem;
	border-top: 2px solid #e2e2e2;
	background-color: #fff;
}

.home-content-section.sponsors-section {
	padding-bottom: 5rem;
	background-color: #fff;
}

.home-content-section.footer-section {
	padding-top: 3rem;
	padding-bottom: 0rem;
	border-top: 4px solid #17488d;
	background-color: #005ea8;
}

.cb-static {
	width: 33.33%;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.cb-static._50percent-left {
	width: 50%;
	padding-right: 3rem;
}

.cb-static._50percent-right {
	width: 50%;
	padding-left: 3rem;
}

.cb-static.footer-cb-1 {
	width: 25%;
	margin-right: 4rem;
	padding-right: 0.5rem;
	padding-bottom: 2rem;
}

.cb-static.footer-cb-3 {
	width: 50%;
	padding-right: 0vw;
}

.cb-static.footer-cb-left-copy {
	width: 25%;
	padding-right: 0.5rem;
	padding-bottom: 2rem;
}

.cb-static.footer-cb-4 {
	width: 50%;
}

.event-image {
	height: 0px;
	margin-bottom: 1rem;
	padding: 31% 0px;
	background-color: #e0e0e0;
	background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
	background-position: 50% 50%;
	background-size: 100%;
	background-repeat: no-repeat;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.event-image:hover {
	background-size: 110%;
}

.event-image.event-image-3 {
	position: relative;
	background-image: url('/20181016082423/assets/images/corn-field.jpg');
}

.event-image.event-image-2 {
	position: relative;
	background-image: url('/20181016082423/assets/images/machinery-in-fields-2.jpg');
	background-position: 50% 50%;
	background-size: 115%;
}

.event-image.event-image-2:hover {
	background-size: 125%;
}

.event-image.event-image-1 {
	position: relative;
	background-image: url('/20181016082423/assets/images/man-in-soybean-crop.jpg');
	background-size: 100%;
}

.event-image.event-image-1:hover {
	background-size: 110%;
}

.footer-section {
	background-color: #1f304f;
}

.footer-grip-link {
	width: 130px;
	height: 28px;
	min-width: 0px;
	padding-left: 32px;
	float: right;
	background-image: url('/20181016082423/assets/images/globalreach_knockout_fullwhite.png');
	background-position: 50% 50%;
	background-size: 130px 28px;
	background-repeat: no-repeat;
	text-decoration: none;
}

.grip-logo-text-box {
	color: #fff;
	font-size: 8px;
	line-height: 8px;
	text-decoration: none;
	text-transform: uppercase;
}

.copyright-paragraph {
	display: inline-block;
	color: #fff;
}

.copyright-paragraph h1 {
	margin: 0px;
	padding: 0px;
	display: inline;
	font-family: inherit;
	line-height: inherit;
	font-size: inherit;
	color: inherit !important;
	font-weight: inherit;
	letter-spacing: inherit;
}

.divider-bar-wrapper {
	display: block;
	width: 35px;
	height: 3px;
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	background-color: #e51837;
}

.divider-bar-wrapper.short-divider {
	width: 30px;
	margin-bottom: 0.5rem;
	background-color: #e51837;
	background-image: none;
}

.cb-heading {
	display: inline-block;
	margin-top: 0rem;
	margin-bottom: 1rem;
	color: #b00000;
	font-size: 2.286rem;
	line-height: 2.986rem;
	font-weight: 400;
}

.cb-heading.clients-cb-head {
	display: block;
	margin-right: auto;
	margin-bottom: 2rem;
	margin-left: auto;
	color: #0a0406;
	font-size: 2.286rem;
	line-height: 2.986rem;
	font-weight: 400;
	text-align: center;
}

.cb-heading.cta-heading {
	opacity: 0.9;
	color: #fff;
	font-size: 2.014rem;
}

.cb-heading.footer-heading {
	display: block;
	margin-bottom: 0.5rem;
	color: #f4f4f4;
	font-size: 1.25rem;
	line-height: 1.95rem;
	font-weight: 600;
}

.cb-heading.cb-heading-secondary {
	color: #0a706b;
}

.cb-head-wrapper {
	display: block;
	margin-top: 2rem;
	margin-bottom: 1rem;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.cb-head-wrapper.sponsorships-header-wrapper {
	margin-top: 0rem;
}

.rotator-arrow {
	display: block;
	width: 50px;
	height: 50px;
	margin-right: auto;
	margin-bottom: 0rem;
	margin-left: auto;
	background-image: url('/20181016082423/assets/images/left-circle-arrow-white-03.svg');
	background-position: 0px 50%;
	background-size: 30px;
	background-repeat: no-repeat;
	opacity: 0.45;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
}

.rotator-arrow:hover {
	opacity: 1;
}

.rotator-arrow.rotator-left-arrow {
	width: 50px;
	height: 50px;
	margin-bottom: 0rem;
	border-right-style: none;
	background-image: url('/20181016082423/assets/images/right-circle-arrow-white-03.svg');
	background-position: 100% 50%;
	background-size: 30px;
}

.cb-subhead {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	color: #2d3047;
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 400;
	text-decoration: none;
}

.cb-subhead.event-title {
	margin-bottom: 0.25rem;
	color: #0a0406;
}

.cb-static-link-block {
	width: 33.33%;
	margin-bottom: 1rem;
	text-decoration: none;
}

.home-content-wrapper {
	display: block;
	margin-right: auto;
	margin-left: auto;
	padding-right: 3rem;
	padding-left: 3rem;
}

.home-content-wrapper.footer-wrapper {
	max-width: 1300px;
}

.paragraph-extra-lineheight {
	line-height: 2rem;
}

.paragraph-extra-lineheight.sponsor-paragraph {
	margin-right: 1rem;
}

.cb-bkgd-image {
	height: 265px;
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	background-image: url('/20181016082423/assets/images/516450790.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.cb-bkgd-image.blog-image {
	display: none;
}

.cb-bkgd-image.sponsor-image {
	display: none;
	background-image: url('/20181016082423/assets/images/DSC_0147.JPG');
}

.cb-content-indented {
	padding-right: 1rem;
	padding-left: 0.5rem;
}

.news-signup-form-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 1px solid #888;
}

.news-signup-field {
	width: 100%;
	margin-bottom: 0px;
	margin-left: 0rem;
	padding: 8px 12px 8px 2px;
	border-style: none;
	background-color: rgba(33, 33, 33, 0.6);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.news-signup-field:focus {
	padding-left: 12px;
	background-color: hsla(0, 0%, 100%, 0.1);
	color: #201c0f;
}

.news-signup-field::-webkit-input-placeholder {
	color: #f4f4f4;
}

.news-signup-field:-ms-input-placeholder {
	color: #f4f4f4;
}

.news-signup-field::-ms-input-placeholder {
	color: #f4f4f4;
}

.news-signup-field::placeholder {
	color: #f4f4f4;
}

.cta-link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 33.33%;
	height: 400px;
	min-height: 300px;
	padding: 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #e2e2e2;
	color: #fff;
	text-align: center;
	text-decoration: none;
}

.cta-link-block.cta-1 {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(10, 112, 107, 0.9)), to(rgba(10, 112, 107, 0.9))), url('/20181016082423/assets/images/131.jpg');
	background-image: linear-gradient(180deg, rgba(10, 112, 107, 0.9), rgba(10, 112, 107, 0.9)), url('/20181016082423/assets/images/131.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.cta-link-block.cta-1:hover {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(10, 112, 107, 0.9)), to(rgba(10, 112, 107, 0.9))), url('/20181016082423/assets/images/131.jpg');
	background-image: linear-gradient(180deg, rgba(10, 112, 107, 0.9), rgba(10, 112, 107, 0.9)), url('/20181016082423/assets/images/131.jpg');
}

.cta-link-block.cta-2 {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(163, 59, 32, 0.9)), to(rgba(163, 59, 32, 0.9))), url('/20181016082423/assets/images/1911h0072.jpg');
	background-image: linear-gradient(180deg, rgba(163, 59, 32, 0.9), rgba(163, 59, 32, 0.9)), url('/20181016082423/assets/images/1911h0072.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.cta-link-block.cta-2:hover {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(163, 59, 32, 0.9)), to(rgba(163, 59, 32, 0.9))), url('/20181016082423/assets/images/1911h0072.jpg');
	background-image: linear-gradient(180deg, rgba(163, 59, 32, 0.9), rgba(163, 59, 32, 0.9)), url('/20181016082423/assets/images/1911h0072.jpg');
}

.cta-link-block.cta-3 {
	min-height: 0px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(45, 48, 71, 0.9)), to(rgba(45, 48, 71, 0.9))), url('/20181016082423/assets/images/businessladycomputer.jpg');
	background-image: linear-gradient(180deg, rgba(45, 48, 71, 0.9), rgba(45, 48, 71, 0.9)), url('/20181016082423/assets/images/businessladycomputer.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.cta-link-block.cta-3:hover {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(45, 48, 71, 0.9)), to(rgba(45, 48, 71, 0.9))), url('/20181016082423/assets/images/businessladycomputer.jpg');
	background-image: linear-gradient(180deg, rgba(45, 48, 71, 0.9), rgba(45, 48, 71, 0.9)), url('/20181016082423/assets/images/businessladycomputer.jpg');
}

.cta-content {
	overflow: hidden;
	height: 0px;
	margin-top: 20px;
	margin-bottom: 20px;
	opacity: 0;
	-webkit-transform: translate(0px, 50px);
	-ms-transform: translate(0px, 50px);
	transform: translate(0px, 50px);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.footer-paragraph {
	margin-bottom: 0rem;
	color: #f4f4f4;
	font-size: 0.9rem;
	line-height: 1.65rem;
}

.login-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 2.5rem;
	float: right;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.login-link {
	margin-right: 0rem;
	margin-left: 0.5rem;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: #f4f4f4;
	font-size: 0.8rem;
	line-height: 1.4rem;
	font-weight: 600;
	letter-spacing: 0.04rem;
	text-decoration: none;
	text-transform: uppercase;
}

.login-link.account {
	padding-left: 1.5rem;
	background-image: url('/20181016082423/assets/images/profile_gray-01.svg');
	background-position: 0px 50%;
	background-size: 16px 20px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #212121;
}

.login-link.account:hover {
	opacity: 0.75;
}

.login-link.cart {
	padding-left: 1.5rem;
	background-image: url('/20181016082423/assets/images/cart_gray-01.svg');
	background-position: 0px 50%;
	background-size: 16px 20px;
	background-repeat: no-repeat;
	-webkit-transition-property: all;
	transition-property: all;
	color: #212121;
}

.login-link.cart:hover {
	opacity: 0.75;
}

.footer-nav-item {
	margin-top: 0rem;
}

.footer-list-item-link {
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: #f4f4f4;
	text-decoration: none;
}

.footer-list-item-link:hover {
	border-bottom: 1px solid #fff;
	color: hsla(0, 0%, 100%, 0.65);
}

.footer-list-item-link.w--current {
	border-bottom: 1px solid #fff;
	color: #fff;
}

.footer-nav-list {
	margin-top: 0rem;
	font-size: 0.9rem;
	line-height: 1.65rem;
}

.event-date-wrapper {
	position: absolute;
	left: 0px;
	top: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 98px;
	height: 90px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: hsla(0, 0%, 100%, 0.85);
}

.event-month {
	color: #0a706b;
	font-size: 0.9rem;
	line-height: 1.35rem;
	font-weight: 600;
	text-align: center;
}

.event-date-big {
	color: #0a706b;
	font-size: 2.286rem;
	line-height: 2rem;
	font-weight: 700;
	text-align: center;
}

.event-details {
	color: #0a0406;
	font-size: 1.143rem;
	line-height: 1.843rem;
	font-style: italic;
	font-weight: 400;
}

.spotlight-content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-content: stretch;
	-ms-flex-line-pack: stretch;
	align-content: stretch;
}

.post-date {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	color: #0a706b;
	font-size: 0.9rem;
	font-weight: 700;
	text-transform: uppercase;
}

.slide-image-wrap {
	width: 100%;
	height: 100%;
	background-color: #fff;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.slide-image-wrap.slide-image-1 {
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.slide-image-wrap.slide-image-4 {
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.slide-image-wrap.slide-7 {
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
}

.event-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.footer-email-link {
	border-bottom: 1px solid #b00000;
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.5rem;
	text-decoration: none;
}

.footer-email-link:hover {
	border-bottom: 1px solid #888;
	color: #6bb4b1;
}

.mobile-menu-arrow {
	display: none;
}

.inside-page-section {
	padding-top: 50px;
	background-color: rgba(51, 51, 51, 0.02);
	background-image: url('/20181016082423/assets/images/textured-bg.png');
	background-position: 0% 100%;
	background-size: auto;
}

.intro-paragraph {
	color: #0a0406;
	font-size: 1.143rem;
	line-height: 1.843rem;
	font-weight: 600;
}

.page-title-wrapper {
	margin-bottom: 1rem;
}

.header-text-link {
	display: inline-block;
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	color: #0a706b;
	text-decoration: none;
}

.header-text-link:hover {
	color: rgba(10, 112, 107, 0.7);
}

.text-link {
	padding-bottom: 1px;
	border-bottom: 1px solid rgba(10, 4, 6, 0.12);
	color: #0a0406;
	line-height: 1.7rem;
	text-decoration: none;
}

.text-link:hover {
	border-bottom-color: transparent;
	color: #005ea8;
}

.breadcrumbs-list {
	display: inline;
	float: left;
	margin-top: -2px;
}

.breadcrumbs-list-item {
	margin-right: 0.5rem;
	padding-right: 0.5rem;
	float: left;
	border-right: 1px solid #bdbdbd;
	line-height: 1.3rem;
}

.breadcrumbslink-lr {
	border-bottom: 1px solid transparent;
	color: #0a0406;
	text-decoration: none;
}

.breadcrumbslink-lr:hover {
	border-bottom: 1px solid #a7a7a7;
	color: #005ea8;
}

.newsletter-button {
	display: inline-block;
	width: 2rem;
	height: 2.5rem;
	background-color: rgba(33, 33, 33, 0.6);
	background-image: url('/20181016082423/assets/images/white-arrow-rounded_1white-arrow-rounded.png');
	background-position: 50% 50%;
	background-size: 7px;
	background-repeat: no-repeat;
	opacity: 1;
	-webkit-transition: background-color 300ms ease;
	transition: background-color 300ms ease;
}

.newsletter-button:hover {
	background-color: #0a706b;
}

.success-message {
	background-color: rgba(105, 181, 164, 0.35);
	font-weight: 600;
	text-align: left;
}

.error-message {
	padding: 20px;
	background-color: rgba(178, 43, 37, 0.2);
	font-weight: 600;
}

.footer-signoff-link-wrap {
	display: inline-block;
	margin-left: 0.75rem;
}

.footer-signoff-link {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	opacity: 1;
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	color: #fff;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.footer-signoff-link:hover {
	border-bottom: 1px solid #fff;
	color: hsla(0, 0%, 100%, 0.65);
}

.footer-signoff-link.first-footer-signoff-link {
	color: #fff;
}

.footer-signoff-link.first-footer-signoff-link:hover {
	border-bottom-color: #fff;
	color: hsla(0, 0%, 100%, 0.65);
}

.signoff-left-wrap {
	display: inline-block;
	color: #bdbdbd;
	font-size: 0.8rem;
	line-height: 1.2rem;
}

.split-50 {
	width: 50%;
}

._50-50-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.social-media-wrapper-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.social-media-wrapper-2.footer-socmed-wrapper {
	margin-top: 1rem;
}

.social-media-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 2rem;
	height: 2rem;
	margin-right: 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #0a706b;
	text-align: center;
}

.social-media-link:hover {
	background-color: #6bb4b1;
}

.header-link {
	padding-bottom: 3px;
	border-bottom: 1px solid rgba(10, 4, 6, 0.12);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #0a0406;
	text-decoration: none;
}

.header-link:hover {
	padding-bottom: 3px;
	border-bottom-color: transparent;
	color: #005ea8;
}

.screen-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

.screen-message.success {
	border-left: 6px solid #11b91e;
	background-color: rgba(17, 185, 30, 0.25);
	font-size: 1.1rem;
}

.screen-message.error {
	border-left: 6px solid #c70616;
	background-color: rgba(253, 54, 54, 0.15);
	font-size: 1.1rem;
}

.screen-message-text {
	font-weight: 500;
}

.right-col-link {
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.right-col-item-wrap {
	margin-bottom: 2rem;
}

.clients-slider {
	position: relative;
	height: 80px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	border-style: none;
	border-width: 1px;
	border-color: rgba(111, 124, 136, 0.15);
	background-color: transparent;
}

.clients-slider-mask {
	position: relative;
	height: 100%;
	padding-right: 2rem;
	padding-left: 2rem;
}

.clients-slider-slide {
	position: relative;
	width: 20%;
	height: 60px;
	min-width: 100px;
	margin-top: 10px;
}

.clients-slider-slide.slide-1 {
	border-left: 1px solid rgba(111, 124, 136, 0.07);
}

.clients-slider-image {
	position: relative;
	top: 50%;
	display: block;
	max-height: 50px;
	max-width: 135px;
	margin-right: auto;
	margin-left: auto;
	-webkit-transform: translate(0px, -50%);
	-ms-transform: translate(0px, -50%);
	transform: translate(0px, -50%);
}

.clients-slider-arrow {
	z-index: 21;
	height: 100%;
	max-width: 2rem;
	-webkit-transition: background-color 300ms ease, color 300ms ease;
	transition: background-color 300ms ease, color 300ms ease;
	color: #01579b;
	font-size: 1.25rem;
}

.clients-slider-arrow:hover {
	background-color: #f8f8f8;
	color: #1f304f;
}

.clients-slider-arrow-icon {
	color: #2c2c2c;
}

.footer-left-content-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
}

.block-quote {
	border-left-color: #e51837;
	background-color: rgba(229, 24, 55, 0);
	color: #0a0406;
}

.news-signup-form-block {
	width: 100%;
	max-width: 225px;
	margin-top: 1rem;
	margin-bottom: 0px;
}

.link {
	text-decoration: none;
}

.footer-right-content-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
}

.left-nav-list-item-2 {
	margin-bottom: 0rem;
}

.left-nav-list-item-2.greatgrandchild-li {
	margin-left: 0.5rem;
}

.left-nav-list-item-2.grandchild-li {
	margin-left: 1rem;
}

.left-nav-link-wrap {
	border-bottom: 1px solid #e0ded6;
}

.left-nav-list-2 {
	margin-top: 0rem;
	margin-bottom: 0rem;
	background-color: transparent;
	font-weight: 400;
}

.footer-grip-link-2 {
	width: 133px;
	height: 29px;
	min-width: 0px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 33px;
	background-image: url('/20181016082423/assets/images/globalreach_dark.svg');
	background-position: 50% 50%;
	background-size: 130px 29px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: none;
	cursor: pointer;
}

.footer-grip-link-2.white-png {
	width: 140px;
	background-image: url('/20181016082423/assets/images/globalreach_white.png');
	background-size: 130px;
}

.grip-logo-text-box-2 {
	color: #000;
	font-size: 0.5rem;
	line-height: 0.5rem;
	font-weight: 700;
	letter-spacing: 0.01rem;
	text-decoration: none;
	text-transform: uppercase;
}

.grip-logo-text-box-2.webdevby-white {
	color: #fff;
}

.footer-directions-link {
	border-bottom: 1px solid #fff;
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.5rem;
	text-decoration: none;
}

.footer-directions-link:hover {
	border-bottom: 1px solid #fff;
	color: hsla(0, 0%, 100%, 0.65);
}

.clients-slider-link {
	display: block;
	width: 100%;
	height: 60px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer-contact-form {
	border-bottom: 1px solid #fff;
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.5rem;
	text-decoration: none;
}

.footer-contact-form:hover {
	border-bottom: 1px solid #fff;
	color: hsla(0, 0%, 100%, 0.65);
}

.heading {
	color: #0a0406;
}

.heading-2 {
	color: #0a0406;
}

.heading-3 {
	color: #0a0406;
}

.paragraph {
	color: #0a0406;
}

.paragraph-2 {
	color: #0a0406;
}

.paragraph-3 {
	color: #0a0406;
}

.heading-4 {
	color: #0a0406;
}

.heading-5 {
	color: #0a0406;
}

.heading-6 {
	color: #0a0406;
}

.paragraph-4 {
	color: #0a0406;
}

.paragraph-5 {
	color: #0a0406;
}

.text-block {
	color: #0a0406;
}

.text-block-2 {
	color: #0a0406;
}

.text-block-3 {
	color: #0a0406;
}

.text-block-4 {
	color: #0a0406;
}

.text-block-5 {
	color: #0a0406;
}

.text-block-6 {
	color: #0a0406;
}

.text-block-7 {
	color: #0a0406;
}

.text-block-8 {
	color: #0a0406;
}

.text-block-9 {
	color: #0a0406;
}

.text-block-10 {
	color: #0a0406;
}

.paragraph-6 {
	color: #0a0406;
}

.text-block-11 {
	color: #0a0406;
}

.text-block-12 {
	color: #0a0406;
}

.text-block-13 {
	color: #0a0406;
}

.paragraph-7 {
	color: #0a0406;
}

.paragraph-8 {
	color: #0a0406;
}

.heading-7 {
	color: #0a0406;
}

.heading-8 {
	color: #0a0406;
}

.heading-9 {
	color: #0a0406;
}

.heading-10 {
	color: #0a0406;
}

.field-label {
	color: #0a0406;
}

.field-label-2 {
	color: #0a0406;
}

.heading-11 {
	color: #0a0406;
}

.heading-12 {
	color: #0a0406;
	margin-bottom: 1.1rem;
}

.heading-13 {
	color: #0a0406;
}

.paragraph-9 {
	color: #0a0406;
}

.paragraph-10 {
	color: #0a0406;
}

.paragraph-11 {
	color: #0a0406;
}

.heading-14 {
	color: #0a0406;
}

.heading-15 {
	color: #0a0406;
}

.heading-16 {
	color: #0a0406;
}

.heading-17 {
	color: #0a0406;
}

@media screen and (max-width: 991px) {
	h1 {
		font-size: 2.286rem;
		line-height: 2.986rem;
	}

	.main-content {
		width: 100%;
	}

	.container {
		max-width: 991px;
	}

	.container.content-container {
		display: block;
		max-width: 991px;
	}

	.container.brand-container {
		display: block;
		max-width: none;
	}

	.container.nav-container {
		display: block;
		max-width: 940px;
		margin-top: 0px;
	}

	.container.home-content-container {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.container.signoff-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-bottom: 0.5rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.container.top-nav-container {
		max-width: 991px;
		padding-right: 1rem;
	}

	.container.footer-content-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.container.events-container {
		max-width: 991px;
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}

	.container.cta-container {
		max-width: 991px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.left-nav-col {
		display: none;
	}

	.breadcrumb-link {
		margin-top: 0.25rem;
	}

	.breadcrumb-current {
		margin-top: 0rem;
	}

	.content-wrapper {
		display: block;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.right-side-col {
		width: 100%;
		max-width: none;
		min-width: 0px;
		margin-top: 1rem;
		margin-left: 0rem;
		padding: 1rem 1.25rem;
		background-color: #f4f4f4;
		display: inline-block;
	}

	.right-side-col h2 {
		margin-top: 1rem;
	}

	.right-side-col p {
		font-size: 1rem;
		line-height: 1.7rem;
	}

	.right-side-col h3 {
		margin-top: 1rem;
	}

	.right-side-col h4 {
		margin-top: 1rem;
	}

	.navbar {
		padding-right: 1rem;
		padding-left: 1.5rem;
	}

	.brand {
		width: 225px;
	}

	.brand.w--current,
	.brand.active {
		width: 200px;
	}

	.nav-menu {
		display: block;
		width: 90%;
		margin-top: 105px;
		background-color: #fff;
		background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 83.1%, 0.5)), to(hsla(0, 0%, 83.1%, 0.5)));
		background-image: linear-gradient(180deg, hsla(0, 0%, 83.1%, 0.5), hsla(0, 0%, 83.1%, 0.5));
		text-align: left;
	}

	.main-nav-link {
		margin-right: 0px;
		margin-left: 0px;
		padding: 1.3rem 1rem 1rem;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
		color: #0a0406;
		line-height: 1rem;
		font-weight: 600;
	}

	.main-nav-link:hover {
		background-color: hsla(0, 0%, 83.1%, 0.5);
		box-shadow: none;
		color: #0a0406;
	}

	.main-nav-link.w--current {
		margin-right: 0px;
		margin-left: 0px;
		background-color: hsla(0, 0%, 83.1%, 0.5);
		box-shadow: none;
		color: #0a0406;
		font-weight: 600;
	}

	.main-nav-link.mobile-duplicate {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 88%;
		margin-right: 0px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom-style: none;
		-webkit-transition-property: all;
		transition-property: all;
	}

	.main-nav-link.mobile-duplicate:hover {
		background-color: transparent;
	}

	.dropdown-wrapper {
		display: none;
	}

	.rotator {
		height: auto;
	}

	.slideshow-arrow {
		top: 57px;
		width: 60px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.slidenav {
		position: absolute;
		left: 0px;
		right: 0px;
		bottom: 0px;
	}

	.slide-caption-wrapper {
		position: static;
		top: 0%;
		display: block;
		overflow: visible;
		width: 100%;
		max-width: none;
		margin-right: auto;
		margin-left: auto;
		padding: 1rem 3rem 3rem;
		background-color: hsla(0, 0%, 83.3%, 0.5);
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.slide-caption-header {
		color: #0a0406;
		font-size: 2.014rem;
		line-height: 2.714rem;
		text-decoration: none;
	}

	.slide-caption-paragraph {
		color: #0a0406;
		font-size: 0.9rem;
		line-height: 1.6rem;
	}

	.home-content-section {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.home-content-section.home-content-section-pattern-bg {
		padding-top: 1.5rem;
		padding-bottom: 4rem;
		border-top-color: #e2e2e2;
	}

	.home-content-section.two-cb-home-content-section {
		padding-bottom: 4rem;
		border-top-color: #e2e2e2;
	}

	.home-content-section.sponsors-section {
		padding-bottom: 3rem;
	}

	.cb-static._50percent-left {
		width: 100%;
		padding-right: 0rem;
	}

	.cb-static._50percent-right {
		width: 100%;
		padding-left: 0rem;
	}

	.cb-static.footer-cb-1 {
		width: auto;
		margin-right: 3rem;
		padding-top: 0rem;
		padding-bottom: 2rem;
	}

	.cb-static.footer-cb-3 {
		width: 50%;
		padding-top: 2rem;
		padding-right: 0rem;
		padding-bottom: 2rem;
	}

	.cb-static.footer-cb-left-copy {
		width: auto;
		padding-top: 0rem;
		padding-bottom: 2rem;
	}

	.cb-static.footer-cb-4 {
		display: block;
	}

	.event-image {
		margin-bottom: 0.75rem;
		background-image: none;
	}

	.footer-grip-link {
		margin-top: 1rem;
	}

	.copyright-paragraph {
		margin-top: 1rem;
	}

	.cb-heading.clients-cb-head {
		font-size: 2.014rem;
		line-height: 2.714rem;
	}

	.cb-heading.cta-heading {
		font-size: 2rem;
		line-height: 2.9rem;
	}

	.cb-heading.footer-heading {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.cb-head-wrapper.sponsorships-header-wrapper {
		padding-left: 2.5rem;
	}

	.rotator-arrow {
		position: relative;
		left: 0px;
		right: 0px;
		bottom: 0px;
		width: 40px;
		height: 40px;
		opacity: 0.35;
	}

	.rotator-arrow:hover {
		opacity: 0.6;
	}

	.rotator-arrow.rotator-left-arrow {
		width: 40px;
		height: 40px;
	}

	.cb-subhead.event-title {
		margin-bottom: 0.125rem;
		font-size: 1.286rem;
		line-height: 1.986rem;
	}

	.cb-static-link-block {
		width: 33.33%;
		margin-bottom: 2rem;
	}

	.home-content-wrapper {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}

	.home-content-wrapper.footer-wrapper {
		padding-right: 2rem;
		padding-left: 2.5rem;
	}

	.cta-link-block {
		height: 300px;
	}

	.login-link.account {
		margin-right: 1rem;
	}

	.footer-nav-list {
		margin-bottom: 0rem;
	}

	.event-date-wrapper {
		width: 84px;
		height: 74px;
	}

	.event-details {
		font-size: 1rem;
		line-height: 1.7rem;
	}

	.spotlight-content-wrapper {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.menu-button {
		position: static;
		top: 25%;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 68px;
		height: 68px;
		margin-bottom: -467px;
		padding: 10px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: transparent;
		-webkit-transform: translate(0px, 25%);
		-ms-transform: translate(0px, 25%);
		transform: translate(0px, 25%);
		-webkit-transition: background-color 300ms ease;
		transition: background-color 300ms ease;
		color: #f8f8f8;
	}

	.menu-button:hover {
		background-color: transparent;
		color: #f8f8f8;
	}

	.menu-button.w--open {
		background-color: transparent;
	}

	.mobile-menu-icon {
		color: #f8f8f8;
		font-size: 2.5rem;
		line-height: 2.5rem;
		text-align: center;
	}

	.menu-button-text {
		margin-top: -8px;
		font-size: 0.7rem;
		line-height: 1rem;
		text-align: center;
		text-transform: uppercase;
	}

	.menu-button-text:hover {
		color: #f8f8f8;
	}

	.slide-image-wrap {
		height: 375px;
		background-position: 50% 50%;
		background-size: cover;
	}

	.slide-image-wrap.slide-image-1 {
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.slide-image-wrap.slide-image-4 {
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.slide-image-wrap.slide-7 {
		background-position: 50% 50%;
		background-size: cover;
	}

	.slide-mask {
		background-color: #2d3047;
	}

	.event-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.mobile-link-label {
		display: inline-block;
		margin-bottom: 0rem;
		line-height: 1rem;
	}

	.mobile-menu-arrow {
		display: block;
		width: 12%;
		padding-right: 0px;
		padding-bottom: 0px;
		border-left: 1px solid hsla(0, 0%, 100%, 0.25);
		border-bottom-color: rgba(31, 48, 79, 0.2);
		background-color: transparent;
		background-image: url('/20181016082423/assets/images/black-arrow-rounded-01.svg');
		background-position: 50% 50%;
		background-size: 8px;
		background-repeat: no-repeat;
	}

	.mobile-menu-arrow:hover {
		background-color: transparent;
	}

	.two-part-mobile-nav-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
	}

	.two-part-mobile-nav-wrapper:hover {
		background-color: hsla(0, 0%, 83.1%, 0.5);
		color: #2d3047;
	}

	.inside-page-section {
		padding-top: 0px;
	}

	.footer-signoff-link-wrap {
		margin-left: 0rem;
	}

	.footer-signoff-link.first-footer-signoff-link {
		margin-left: 0rem;
	}

	.footer-signoff-link.last-footer-signoff-link {
		margin-right: 0rem;
	}

	.signoff-left-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	._50-50-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.social-media-wrapper-2.footer-socmed-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.social-media-link {
		width: 2rem;
		height: 2rem;
		margin-right: 1rem;
		margin-bottom: 1rem;
		margin-left: 0rem;
		background-color: #0a706b;
	}

	.clients-slider {
		margin-right: 0.75rem;
		margin-left: 0.75rem;
	}

	.clients-slider-slide {
		width: 25%;
	}

	.footer-left-content-wrap {
		width: 100%;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom: 1px none hsla(0, 0%, 100%, 0.15);
	}

	.footer-right-content-wrap {
		width: 100%;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.15);
	}

	.footer-grip-link-2.white-png {
		margin-top: 1.5rem;
	}

	.heading-12 {
		margin-bottom: 1rem;
		margin-top: 1.7rem;
	}
}

@media screen and (max-width: 767px) {
	ul {
		padding-left: 1rem;
	}

	ol {
		padding-left: 1.25rem;
	}

	.container {
		max-width: 767px;
	}

	.container.content-container {
		max-width: 767px;
	}

	.container.brand-container {
		max-width: 728px;
	}

	.container.nav-container {
		max-width: 728px;
	}

	.container.home-content-container {
		padding-right: 2rem;
		padding-left: 2rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.container.signoff-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: auto;
		margin-left: auto;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		text-align: center;
	}

	.container.footer-content-container {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.container.events-container {
		max-width: 767px;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.container.cta-container {
		max-width: 767px;
	}

	.breadcrumb-current {
		margin-top: 0rem;
	}

	.navbar {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.brand {
		margin-left: 0.65rem;
	}

	.brand.w--current,
	.brand.active {
		margin-left: 0rem;
		padding-left: 0px;
	}

	.main-nav-link.mobile-duplicate {
		width: 88%;
	}

	.rotator {
		height: auto;
	}

	.slideshow-arrow {
		top: 25px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.slide-caption-wrapper {
		max-width: 100%;
	}

	.slide-caption-header {
		font-size: 1.714rem;
		line-height: 2.414rem;
	}

	.home-content-section {
		padding-top: 3rem;
	}

	.home-content-section.home-content-section-pattern-bg {
		border-top-width: 2px;
		border-top-color: #e2e2e2;
	}

	.home-content-section.two-cb-home-content-section {
		padding-bottom: 2rem;
		border-top-color: #e2e2e2;
	}

	.home-content-section.sponsors-section {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.home-content-section.footer-section {
		padding-top: 2rem;
	}

	.cb-static {
		width: 33.33%;
	}

	.cb-static.footer-cb-1 {
		width: 33%;
		margin-right: 1.5rem;
		padding-bottom: 2rem;
	}

	.cb-static.footer-cb-3 {
		padding-right: 2rem;
	}

	.cb-static.footer-cb-left-copy {
		width: 33%;
		padding-right: 0rem;
	}

	.footer-grip-link {
		display: block;
		margin-top: 2rem;
		margin-right: 0px;
		margin-left: 0px;
		float: none;
	}

	.copyright-paragraph {
		margin-top: 1rem;
		margin-right: 0rem;
	}

	.cb-heading.clients-cb-head {
		line-height: 2.714rem;
	}

	.cb-head-wrapper.sponsorships-header-wrapper {
		padding-left: 0rem;
	}

	.rotator-arrow {
		position: relative;
		top: -63px;
		background-size: 25px;
	}

	.rotator-arrow.rotator-left-arrow {
		background-size: 25px;
	}

	.cb-static-link-block {
		width: 100%;
		margin-bottom: 2rem;
	}

	.home-content-wrapper {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.home-content-wrapper.footer-wrapper {
		padding-right: 1.5rem;
		padding-left: 2rem;
	}

	.paragraph-extra-lineheight.sponsor-paragraph {
		margin-right: 0rem;
	}

	.cta-link-block {
		width: 100%;
		height: 300px;
	}

	.cta-link-block.cta-1 {
		height: 300px;
		background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(10, 112, 107, 0.9)), to(rgba(10, 112, 107, 0.9))), url('/20181016082423/assets/images/131.jpg');
		background-image: linear-gradient(180deg, rgba(10, 112, 107, 0.9), rgba(10, 112, 107, 0.9)), url('/20181016082423/assets/images/131.jpg');
		background-size: auto, cover;
	}

	.cta-link-block.cta-1:hover {
		background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(10, 112, 107, 0.9)), to(rgba(10, 112, 107, 0.9))), url('/20181016082423/assets/images/131.jpg');
		background-image: linear-gradient(180deg, rgba(10, 112, 107, 0.9), rgba(10, 112, 107, 0.9)), url('/20181016082423/assets/images/131.jpg');
		background-size: auto, cover;
	}

	.cta-link-block.cta-2 {
		width: 100%;
		background-image: -webkit-gradient(linear, left top, left bottom, from(null), to(null)), url('/20181016082423/assets/images/1911h0072.jpg');
		background-image: linear-gradient(180deg, null, null), url('/20181016082423/assets/images/1911h0072.jpg');
		background-size: auto, cover;
	}

	.cta-link-block.cta-2:hover {
		background-image: -webkit-gradient(linear, left top, left bottom, from(null), to(null)), url('/20181016082423/assets/images/1911h0072.jpg');
		background-image: linear-gradient(180deg, null, null), url('/20181016082423/assets/images/1911h0072.jpg');
		background-size: auto, cover;
	}

	.cta-link-block.cta-3 {
		width: 101%;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		background-image: -webkit-gradient(linear, left top, left bottom, from(null), to(null)), url('/20181016082423/assets/images/businessladycomputer.jpg');
		background-image: linear-gradient(180deg, null, null), url('/20181016082423/assets/images/businessladycomputer.jpg');
		background-size: auto, cover;
	}

	.cta-link-block.cta-3:hover {
		background-image: -webkit-gradient(linear, left top, left bottom, from(null), to(null)), url('/20181016082423/assets/images/businessladycomputer.jpg');
		background-image: linear-gradient(180deg, null, null), url('/20181016082423/assets/images/businessladycomputer.jpg');
		background-size: auto, cover;
	}

	.login-wrapper {
		border-right-color: hsla(0, 0%, 100%, 0);
	}

	.footer-nav-item {
		margin-top: 0rem;
		margin-bottom: 0rem;
	}

	.footer-nav-list {
		margin-left: 0rem;
	}

	.event-date-wrapper {
		width: 90px;
		height: 80px;
	}

	.spotlight-content-wrapper {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.slide-image-wrap {
		height: 225px;
		background-color: transparent;
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: repeat;
	}

	.slide-image-wrap.slide-image-1 {
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.mobile-menu-arrow {
		width: 12%;
	}

	.breadcrumbs-list {
		display: inline-block;
		margin-bottom: 0.35rem;
	}

	.footer-signoff-link-wrap {
		display: block;
		margin-left: 0rem;
	}

	.signoff-left-wrap {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.social-media-link {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}

	.clients-slider-slide {
		width: 50%;
		margin-right: 0.5rem;
	}

	.clients-slider-slide.slide-1 {
		width: 50%;
		margin-right: 0.5rem;
	}

	.footer-left-content-wrap {
		width: 100%;
		border-bottom: 1px none hsla(0, 0%, 100%, 0.15);
	}

	.footer-right-content-wrap {
		padding-top: 0rem;
		padding-bottom: 0rem;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.15);
	}

	.heading-12 {
		margin-bottom: 1.1rem;
		margin-top: 1.3rem;
	}
}

@media screen and (max-width: 479px) {
	h1 {
		font-size: 1.714rem;
		line-height: 2.414rem;
	}

	.container {
		max-width: none;
	}

	.container.content-container {
		max-width: none;
		padding-bottom: 2rem;
	}

	.container.brand-container {
		margin-left: 0px;
	}

	.container.nav-container {
		max-width: none;
	}

	.container.signoff-container {
		display: block;
		margin-right: auto;
		margin-left: auto;
	}

	.container.top-nav-container {
		padding-right: 0.75rem;
		padding-left: 1rem;
	}

	.container.footer-content-container {
		text-align: center;
	}

	.container.events-container {
		max-width: none;
	}

	.container.cta-container {
		max-width: none;
	}

	.navbar {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.logo {
		display: block;
		width: 225px;
		margin-right: auto;
		margin-left: 0px;
	}

	.brand {
		width: 200px;
		height: 65px;
		margin: 0.75rem auto 0.75rem 0rem;
		padding-left: 0px;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}

	.brand.w--current,
	.brand.active {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: auto;
		margin-left: auto;
		padding-left: 0px;
		float: none;
	}

	.nav-menu {
		margin-top: 90px;
	}

	.main-nav-link.mobile-duplicate {
		width: 83%;
	}

	.slideshow-arrow {
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.slide-caption-wrapper {
		margin-right: 1.5rem;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.slide-caption-header {
		font-size: 1.5rem;
		line-height: 2.2rem;
	}

	.slide-caption-paragraph {
		margin-bottom: 0.5rem;
		font-size: 0.9rem;
		line-height: 1.6rem;
	}

	.home-content-section {
		padding-top: 2rem;
		padding-bottom: 0rem;
	}

	.home-content-section.sponsors-section {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.cb-static._50percent-left {
		padding-top: 0rem;
	}

	.cb-static._50percent-right {
		padding-bottom: 0rem;
	}

	.cb-static.footer-cb-1 {
		width: 100%;
		margin-right: 0rem;
		padding-right: 0rem;
	}

	.cb-static.footer-cb-3 {
		width: 100%;
		padding-top: 1rem;
		padding-right: 0rem;
	}

	.cb-static.footer-cb-left-copy {
		width: 100%;
	}

	.cb-static.footer-cb-4 {
		width: 100%;
	}

	.event-image.event-image-3 {
		height: 165px;
	}

	.event-image.event-image-2 {
		height: 165px;
	}

	.event-image.event-image-1 {
		height: 165px;
	}

	.footer-grip-link {
		display: block;
		margin-right: auto;
		margin-left: auto;
	}

	.copyright-paragraph {
		margin-right: 0rem;
	}

	.cb-subhead.event-title {
		font-size: 1.143rem;
		line-height: 1.843rem;
	}

	.home-content-wrapper {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.home-content-wrapper.footer-wrapper {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.paragraph-extra-lineheight {
		font-size: 0.9rem;
		line-height: 1.9rem;
	}

	.login-wrapper {
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.login-link {
		font-weight: 400;
		text-align: center;
		margin-left: 0;
		align-items: center;
		line-height: 0.9rem;
		justify-content: center;
	}

	.footer-nav-item {
		margin-bottom: 0.5rem;
	}

	.footer-nav-list {
		margin-top: 0rem;
		margin-bottom: 0rem;
		margin-left: 0rem;
	}

	.event-date-wrapper {
		width: 84px;
		height: 74px;
	}

	.spotlight-content-wrapper {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.menu-button {
		position: relative;
		top: 0%;
		bottom: 15px;
		width: 54px;
		height: 54px;
		margin-bottom: 0px;
		padding: 8px 6px;
	}

	.menu-button-text {
		display: none;
		font-size: 0.7rem;
	}

	.slide-image-wrap {
		height: 200px;
	}

	.slide-image-wrap.slide-image-1 {
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.mobile-menu-arrow {
		width: 17%;
	}

	.intro-paragraph {
		font-size: 1rem;
		line-height: 1.7rem;
	}

	.footer-signoff-link {
		margin-right: 0.25rem;
		margin-left: 0.25rem;
	}

	.split-50 {
		width: 100%;
	}

	._50-50-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.social-media-wrapper-2.footer-socmed-wrapper {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.clients-slider {
		height: auto;
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.clients-slider-mask {
		height: auto;
	}

	.clients-slider-slide {
		width: 100%;
		height: auto;
		margin-top: 0px;
	}

	.clients-slider-slide.slide-1 {
		width: 100%;
		height: auto;
	}

	.clients-slider-image {
		max-height: 75px;
		max-width: 150px;
	}

	.footer-left-content-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.news-signup-form-block {
		max-width: none;
	}

	.footer-right-content-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}

/********************** OLD Styles *********************/
/********************************************************/
table .header {
	background: #800000 url("/images/header.png") 0 0 repeat-x;
	color: #ffffff;
	font-size: 14px;
	font-weight: bold;
	line-height: 15px;
	padding: 7px 10px;
}

table .subHeader {
	background-color: #285578;
	color: #ffffff;
	font-size: 14px;
	font-weight: bold;
	line-height: 15px;
	padding: 7px 10px;
}

.evenrow {
	background-color: #ffffff;
	color: #333333;
	padding: 6px;
}

.oddrow {
	background-color: #edebeb;
	color: #333333;
	padding: 6px;
}

.button,
.button-inverse:hover {
	background-color: #b00000;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border: none;
	border-radius: 2px;
	color: #ffffff;
	display: inline-block;
	font-size: 14px;
	font-weight: bold;
	line-height: 16px;
	padding: 7px 10px;
	text-align: center;
	text-decoration: none;
	width: 100px;
}

.button:hover,
.button-inverse {
	background-color: #285578;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	color: #ffffff;
	display: inline-block;
	font-size: 14px;
	font-weight: bold;
	line-height: 16px;
	padding: 7px 10px;
	text-align: center;
	text-decoration: none;
	width: 100px;
}


.clearBoth {
	clear: both;
}

.clear:after {
	clear: both;
}

.clear:before,
.clear:after {
	content: "";
	display: table;
}

.clear:before,
.clear:after {
	content: "";
	display: table;
}

.clear {
	clear: both;
}


.list-table {
	width: 100%;
	border-collapse: collapse;
}

.list-table tbody {
	border-top: 1px solid #555555;
	border-bottom: 1px solid #555555;
	margin: 10px 0px;
}

.list-table th {
	text-align: left;
	padding-bottom: 4px;
}

.list-table td {
	padding: 3px 2px;
}

.pagination {
	font-size: 11px;
	margin: 16px 0;
	text-transform: uppercase;
}

.pagination a {
	color: #777;
}

.pagination select {
	border: 1px solid #dedede;
	cursor: pointer;
	margin: 0 2px;
}

.pagination select option {
	padding-left: 4px;
}

.pagination .previous {
	margin-right: 20px;
}

.pagination .page {
	margin-right: 60px;
}

.pagination .showing {
	margin-right: 58px;
}

.pagination .next {
	float: right;
}

table tr.odd-row td {
	background-color: #edebeb;
}

table tr.even-row td {
	background-color: #ffffff;
}

.required_message {
	text-align: right;
}

.required_message span {
	background: url("/core/public/shared/assets/images/required.gif") no-repeat center left;
	padding-left: 16px;
}

.cms_form .validation-error {
	border: 1px solid #ff0000;
}

#accountLinks {
	color: #ffffff;
	position: absolute;
	right: 0px;
	top: 10px;
}

#accountLinks a {
	color: #ffffff;
}

/* Form Elements */
.ui-form-fields {
	border-bottom: #ccc dotted 1px;
	padding: 0px 0px 0px 240px;
}

.ui-form-fields:first-child {
	border-top: #ccc solid 1px;
}

.ui-form-fields:last-child {
	border: none;
}

.ui-form-field {
	display: inline;
	float: left;
	line-height: 30px;
	padding: 6px 0px;
	position: relative;
}

.ui-form-field.inline {
	margin: 0px 0px 0px 6px;
}

.ui-form-error {
	clear: both;
	line-height: normal;
	margin: 3px 0px 0px 0px;
}

.ui-form-error em {
	color: #ff0000;
	display: block;
	font-size: 11px;
	font-style: normal;
	line-height: normal;
	margin: 0px 0px 0px 0px;
}

.ui-form-input {
	line-height: 30px;
	min-height: 30px;
	overflow: hidden;
}

.inline .ui-form-input {
	display: inline;
	float: left;
}

.ui-form-button button {
	display: inline;
	float: left;
}

.ui-form-checkbox input {
	display: inline;
	float: left;
	height: 13px;
	margin: 9px 0px 0px 0px;
	width: 13px;
}

.ui-form-select select {
	display: inline;
	float: left;
}

.error select {
	border-color: #ff0000;
}

.ui-form-text input {
	display: inline;
	float: left;
	height: 17px;
}

.error input {
	border-color: #ff0000;
}

.ui-form-textarea textarea {
	display: inline;
	float: left;
}

.error textarea {
	border-color: #ff0000;
}

.ui-form-label {
	color: #000000;
	height: 18px;
	left: -240px;
	line-height: 18px;
	padding: 6px 6px 6px 0px;
	position: absolute;
	text-align: right;
	top: 6px;
	width: 234px;
}

.inline .ui-form-label {
	display: inline;
	float: left;
	margin: 0px 12px 0px 0px;
	padding: 6px 0px;
	position: static;
	text-align: left;
	width: auto;
}

.required .ui-form-label {
	font-weight: bold;
}

.required .ui-form-label em,
.required.ui-form-legend em {
	padding: 0;
	margin: 0;
	line-height: 0;
	width: 8px;
	height: 8px;
	display: inline-block;
	background: url("/core/public/shared/assets/images/required.gif");
	color: #f6f6f6;
	position: relative;
	bottom: 5px;
	right: 1px;
}

.ui-form-note {
	clear: both;
	line-height: normal;
	margin: 3px 0px 0px 0px;
}

.ui-form-note small {
	display: block;
	font-size: 11px;
	line-height: normal;
	margin: 0px 0px 0px 0px;
}

.ui-form-note small strong {
	font-size: 11px;
	line-height: normal;
	font-weight: bold;
}

.ui-datepicker-input-label {
	position: relative;
	left: -16px;
}

.checkbox-container > div {
	display: block;
	float: left;
}

.checkbox-container > div .checkbox-row {
	padding-bottom: 2px;
}

.checkbox-container > div .checkbox-row input[type=checkbox] {
	position: relative;
	top: 1px;
}

.ui-form-field input[type=password],
.ui-form-field input[type=text] {
	background: #ffffff;
	border: #999999 solid 1px;
	border-radius: 3px;
	box-shadow: 0px 0px 6px #cccccc inset;
	height: 18px;
	padding: 5px;
}

.ui-form-field input[type=password]:disabled,
.ui-form-field input[type=text]:disabled {
	background: #f6f6f6;
	box-shadow: none;
	color: #999999;
	cursor: default;
	text-shadow: 0px 1px #ffffff;
}

.ui-form-field input[type=password]:focus,
.ui-form-field input[type=text]:focus {
	box-shadow: 0px 0px 6px #b2cce5;
	border-color: #336699;
}


.ui-form-field option {
	padding: 3px 17px 3px 3px;
}

.ui-form-field select {
	background: #ffffff;
	border: #999999 solid 1px;
	border-radius: 3px;
	box-shadow: 0px 0px 6px #cccccc inset;
	padding: 5px;
	cursor: pointer;
	height: 29px;
}

.ui-form-field select:disabled {
	background: #f6f6f6;
	box-shadow: none;
	color: #999999;
	cursor: default;
	text-shadow: 0px 1px #ffffff;
}

.ui-form-field select:focus {
	box-shadow: 0px 0px 6px #b2cce5;
	border-color: #336699;
}

.ui-form-buttons {
	margin: 3px 0px 0px 0px;
	overflow: hidden;
	padding: 12px 0px 0px 234px;
	display: flex;
	align-items: center;
}

.ui-form-buttons .ui-form-field {
	padding: 0px 6px;
}

.ui-form-buttons .ui-form-button {
	display: inline;
	float: left;
}

.button,
button[type="submit"] {
	background-color: #b00000;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border: none;
	border-radius: 2px;
	color: #ffffff;
	display: inline-block;
	font-size: 14px;
	font-weight: bold;
	line-height: 16px;
	padding: 7px 10px;
	text-align: center;
	text-decoration: none;
	height: auto;
	width: 100px;
	cursor: pointer;
}

.button:hover,
button[type="submit"]:hover {
	background-color: #285578;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	color: #ffffff;
	display: inline-block;
	font-size: 14px;
	font-weight: bold;
	line-height: 16px;
	padding: 7px 10px;
	text-align: center;
	text-decoration: none;
	width: 100px;
}

.ui-form-fieldset {
	border: none;
}

.ui-form-legend {
	border-bottom: solid 1px #285578;
	margin-bottom: 4px;
}

.ui-form-fieldset .ui-form-fields:first-child {
	border-top: none;
}

/****** Grid Actions ******/
.ui-icon-add {
	display: inline-block;
	height: 16px;
	float: right;
	padding-left: 20px;
	background: url("/core/public/shared/assets/images/icons/star.png") no-repeat;
	line-height: 18px;
	margin-top: 10px;
	color: #285578;
}

.ui-icon-edit {
	display: inline-block;
	width: 16px;
	height: 16px;
	text-indent: -9999px;
	margin: 0 4px;
	background: url("/core/public/shared/assets/images/icons/edit.gif") no-repeat;
}

.ui-icon-mark-for-deletion {
	display: inline-block;
	width: 16px;
	height: 16px;
	text-indent: -9999px;
	margin: 0 4px;
	background: url("/local/websites/unitedservices.net/www/images/mark_for_deletion.png") no-repeat;
}

.ui-icon-mark-for-deletion-off {
	display: inline-block;
	width: 16px;
	height: 16px;
	text-indent: -9999px;
	margin: 0 4px;
	background: url("/local/websites/unitedservices.net/www/images/mark_for_deletion_off.png") no-repeat;
}

.ui-icon-delete {
	display: inline-block;
}

.ui-icon-delete button,
.ui-icon-delete button:hover {
	width: 16px;
	height: 16px;
	padding: 0;
	text-indent: -9999px;
	margin: 0 4px;
	background: url("/core/public/shared/assets/images/icons/delete.gif") no-repeat;
}

.ui-icon-delete-disabled {
	display: block;
	width: 16px;
	height: 16px;
	margin: 0 4px;
	background: url("/core/public/shared/assets/images/icons/delete_disabled.gif") no-repeat;
}

/********** grid **********/
.grid-table {
	width: 100%;
	border-collapse: collapse;
}

.grid-table tbody tr.data:hover {
	background: #ffc;
}

.grid-table thead tr {
	background: #ffffff;
	background: -moz-linear-gradient(top, #ffffff 0%, #eeeeee 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(100%, #eeeeee));
	background: -webkit-linear-gradient(top, #ffffff 0%, #eeeeee 100%);
	background: -o-linear-gradient(top, #ffffff 0%, #eeeeee 100%);
	background: -ms-linear-gradient(top, #ffffff 0%, #eeeeee 100%);
	background: linear-gradient(to bottom, #ffffff 0%, #eeeeee 100%);
	filter: progid:dximagetransform.microsoft.gradient( startColorstr='#ffffff', endColorstr='#eeeeee', GradientType=0 );
}

.grid-table th {
	text-align: left;
	padding: 8px 0 8px 12px;
	border: solid 1px #d0d0d0;
	color: #285578;
	font-weight: normal;
	min-width: 40px;
}

.grid-table th.sortable {
	cursor: pointer;
}

.grid-table th:first-child {
	border-left: none;
	min-width: 0px;
}

.grid-table th:last-child {
	border-right: none;
}

.grid-table tr.data td {
	padding: 8px 0 8px 12px;
	border-top: solid 1px #d0d0d0;
	border-bottom: solid 1px #d0d0d0;
}

.grid-table .action-column {
	padding: 0;
	text-align: center;
	width: 96px;
}

.grid-table .actions {
	padding: 0 6px;
	text-align: center;
}

.grid-table .center {
	text-align: center;
	padding: 8px 6px 8px 6px;
}

/********** property-line-items **********/
.property-line-items {
	width: 100%;
	border-collapse: collapse;
}

.property-line-items th {
	font-weight: normal;
	text-align: left;
}

.property-line-items td {
	border-top: 1px dotted #ccc;
	border-bottom: 1px dotted #ccc;
	padding: 10px 6px 10px 0;
}

.property-line-items .photo {
	width: 212px;
}

.property-line-items .actions {
	width: 3px;
}

.property-line-items .item-num {
	text-align: center;
	width: 46px;
	padding: 0;
}

.property-line-items .ui-form-error {
	position: absolute;
}

.property-line-items .ui-form-error em {
	white-space: nowrap;
}

.file-upload-container {
	width: 150px;
	height: 26px;
	overflow: hidden;
	position: relative;
	top: 4px;
}

.file-upload-container .file-upload {
	background: none transparent;
	border: none;
	opacity: 0;
	font-size: 300px;
	position: absolute;
	right: -30px;
	top: -30px;
}

.file-name {
	width: 150px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 10px;
	text-transform: lowercase;
}

form.grid-filters {
	float: left;
}

.grid-filters .ui-form-buttons {
	border: none;
	float: left;
	margin-left: 8px;
	margin-top: 7px;
	padding: 0;
}

.grid-filters .ui-form-buttons .ui-form-button {
	height: 20px;
	line-height: 20px;
}

.grid-filters .ui-form-buttons .ui-form-button .button,
.grid-filters .ui-form-buttons .ui-form-button button[type='submit'] {
	padding: 4px 5px;
	width: auto;
}

#grid-overlay {
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0.25);
	color: #ffffff;
	display: none;
	font-weight: bold;
	position: absolute;
	z-index: 20;
}

#grid-overlay > span {
	display: block;
	font-size: 13px;
	margin: auto;
	width: 78px;
}

.ui-form-field.changed .ui-form-note {
	background-color: #ffffaa;
}

.ui-form-field.changed.deleted .ui-form-note {
	background-color: #ffcccc;
}

.ui-form-field.changed.added .ui-form-note {
	background-color: #ccffcc;
}

/*Staff Directory Module*/
.cms_list_subheading {
	color: #b00000;
	font-size: 21px;
	font-weight: normal;
	line-height: 23px;
	margin: 2px 0 8px 0;
	padding: 0;
}

#staffdirectory_module.cms_list .cms_list_item .cms_metadata1.cms_title {
	font-size: 16px;
	margin-bottom: 0px;
}

#staffdirectory_module.cms_list .cms_list_item .cms_metadata2.cms_title {
	font-style: italic;
	margin-bottom: 5px;
}

#staffdirectory_module.cms_entity .image {
	float: right;
	margin: 0 10px 10px 10px;
}

#staffdirectory_module.cms_entity .cms_metadata1.cms_title h3 {
	margin-bottom: 20px;
	font-size: 16px;
	font-style: italic;
}

/***************** Webflow ***********************/
/*************************************************/
html {
	font-size: 16px;
}

body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

/* CONTROL PHONE NUMBER COLOR */
.footer-section a[href^=tel] {
	color: #f4f4f4 !important;
	text-decoration: none;
}

/* Mozilla based browsers */
::-moz-selection {
	background-color: #000000;
	color: #ffffff;
}

/* Safari browser */
::selection {
	background-color: #000000;
	color: #ffffff;
}

/* Adjust header on narrower desktop views */
@media screen and (min-width: 1036px) and (max-width: 1140px) {
	.brand {
		width: 225px;
	}

	.main-nav-link {
		font-size: 0.9rem;
	}

	.container.top-nav-container {
		padding-right: 2.25rem;
		padding-left: 2.25rem;
	}

	.navbar {
		padding-right: 2.25rem;
		padding-left: 2.25rem;
	}
}

@media screen and (min-width: 992px) and (max-width: 1035px) {
	.brand {
		width: 200px;
		margin-right: 1rem;
	}

	.main-nav-link {
		font-size: 0.9rem;
	}

	.nav-menu {
		margin-left: 0rem;
	}

	.container.top-nav-container {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.navbar {
		padding-right: 2rem;
		padding-left: 2rem;
	}
}

/* Adjust logo on super small mobile */
@media screen and (max-width: 330px) {
	.brand {
		width: 180px;
		margin-left: 0.25rem;
	}
}

/* Sticky/Flexy Header */
.sticky.header-section {
	position: fixed;
	top: 0;
}

.sticky .newssignup-social-container {
	max-height: 0;
	opacity: 0;
}

.sticky .brand {
	height: 78px;
	width: 165px;
	margin-top: 2px;
}

.sticky .logo {
	height: Auto;
	width: Auto;
}

.sticky .nav-container {
	margin-top: 27px;
}

.sticky .main-nav-link {
	font-size: 0.9rem;
}

.sticky.header-section ~ .rotator-section {
	margin-top: 100px;
}

.w-slider-dot {
	width: 46px;
	height: 6px;
}

/***************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/

/****************** Accesible menu **********************/
/*****************************************************/
.accesible-navigation-menu li.dropdown-wrapper .container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	left: 0;
	transition: max-height 100ms ease 0s, opacity 200ms ease 0s;
	height: 0;
}

.accesible-navigation-menu li.dropdown-wrapper .container-wrapper ul {
	display: none;
	padding: 0;
	border-radius: 0;
	background-color: #17488d;
	margin: 0px;
	list-style-type: none;
	min-width: 200px;
}

.accesible-navigation-menu li.dropdown-wrapper .container-wrapper ul a {
	padding: 0.5rem;
	transition: all 200ms ease;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.3rem;
	font-weight: 400;
	text-decoration: none;
	text-align: left;
	display: inline-block;
	width: 100%;
}

.accesible-navigation-menu li.dropdown-wrapper .container-wrapper ul a:hover,
.accesible-navigation-menu li.dropdown-wrapper .container-wrapper ul a.w--current {
	background-color: #223a66;
	color: #fff;
	font-weight: 400;
}

.accesible-navigation-menu li.dropdown-wrapper.open .container-wrapper {
	opacity: 1;
	max-height: 500px;
	height: auto;
}

.accesible-navigation-menu li.dropdown-wrapper.open .container-wrapper ul {
	display: inline-block;
	padding: 0.5rem;
}

.accesible-navigation-menu li#menu-item-contact.dropdown-wrapper .container-wrapper {
	left: -30px;
}

@media (max-width: 991px) {
	.accesible-navigation-menu {
		display: none !important;
	}
}

/****************** Mobile menu **********************/
/*****************************************************/
.mobile-navigation-menu li[data-breakpoints="xl"] {
	display: flex !important;
}

.mobile-navigation-menu li[data-breakpoints="md,lg,xl"] {
	display: flex !important;
}

.mobile-navigation-menu li[data-breakpoints="xs,sm,md,lg,xl"] {
	display: flex !important;
}

@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu li[data-breakpoints="xl"] {
		display: none !important;
	}

	.mobile-navigation-menu li[data-breakpoints="md,lg,xl"] {
		display: flex !important;
	}

	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

@media (max-width: 479px) {
	.mobile-navigation-menu li[data-breakpoints="xl"] {
		display: none !important;
	}

	.mobile-navigation-menu li[data-breakpoints="md,lg,xl"] {
		display: none !important;
	}
}

.mobile-navigation-menu > .mm-panels > .mm-panel:first-child > .mm-navbar {
	display: none;
}

.mobile-navigation-menu,
.mm-panels,
.mm-panels .mm-panel,
.mm-navbar {
	background-color: #fff;
	background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 83.1%, 0.5)), to(hsla(0, 0%, 83.1%, 0.5)));
	background-image: linear-gradient(180deg, hsla(0, 0%, 83.1%, 0.5), hsla(0, 0%, 83.1%, 0.5));
}

.mobile-navigation-menu li a.nav-link,
.mm-navbar__title {
	text-align: left;
	padding: 1.3rem 1rem 1rem;
	font-size: 1rem;
	color: #0a0406;
	line-height: 1rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
	transition: color 300ms ease;
}

.mm-navbar__title > span {
	font-size: 1rem;
	color: #0a0406;
	line-height: 1rem;
	font-weight: 600;
}

.mm-listitem:after {
	left: 0;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
}

.mm-navbar {
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
}

.mobile-navigation-menu li.mm-listitem.active,
.mobile-navigation-menu li.mm-listitem:hover {
	background-color: hsla(0, 0%, 83.1%, 0.5);
	box-shadow: none;
	color: #0a0406;
}

.mm-listitem_selected > .mm-listitem__text {
	background-color: transparent;
}

.mm-navbar > * {
	justify-content: flex-start;
}

.mm-btn_next:after,
.mm-btn_prev:before {
	border-width: 1.5px;
	border-color: #000;
}

.mm-listitem__btn:not(.mm-listitem__text) {
	border-left-width: 1px;
	border-left-style: solid;
}

.mm-listitem__btn {
	border-color: hsla(0, 0%, 100%, 0.25);
}

/********************* Homepage *********************/
/*****************************************************/
.event-details p,
.event-details a,
.event-details li,
.event-details td,
.event-details span {
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
}

.event-details p {
	color: inherit;
}

.slide-caption-paragraph p:last-child {
	margin-bottom: 0px;
}

.home-content-section.carrier-partners-section {
	position: relative;
	padding-top: 3rem;
	padding-bottom: 3rem;
	background-color: #fafafa;
	background-image: none;
	background-size: auto;
}

#partners_module .cycle-slideshow-carousel .partner-carousel-slide {
	text-align: center;
	min-width: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

#partners_module .cycle-slideshow-carousel .partner-carousel-image {
	display: inline-block;
	max-width: 115px;
	height: auto;
	width: auto;
	max-height: 65px;
}

#partners_module .cycle-slideshow-carousel .clients-slider-arrow.w-slider-arrow-left.slick-arrow {
	left: -25px;
}

#partners_module .cycle-slideshow-carousel .clients-slider-arrow.w-slider-arrow-right.slick-arrow {
	right: -25px;
}

@media screen and (max-width: 479px) {
	#partners_module .cycle-slideshow-carousel .partner-carousel-image {
		max-height: 75px;
		max-width: 150px;
	}
}

/*********************** Slideshow ********************/
/*****************************************************/
.slide-caption-paragraph p {
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-family: inherit;
	font-weight: inherit;
}

.slide-caption-paragraph p:last-child {
	margin-bottom: 0px;
}

.slideshow-button {
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	.rotator {
		background-color: transparent;
	}

	.slide-caption-wrapper {
		background-color: transparent;
	}

	.slide-wrap {
		background-color: hsla(0, 0%, 83.3%, 0.5);
	}

	.slide-image-wrap.slide-image-1 {
		background-color: #fff;
	}

	.slide-mask {
		background-color: transparent;
	}
}

/****************** Inside Page ******************/
/*************************************************/
@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

.breadcrumbs-list li:last-child a,
.breadcrumbs-list li:last-child a:hover {
	color: #005ea8;
	border-bottom: 1px solid transparent;
}

.breadcrumbs-list li:last-child {
	border-right: 0px;
}


.left-nav-link.active {
	margin-left: 0rem;
	padding-right: 0.5rem;
	padding-left: 1rem;
	background-image: url('/20181016082423/assets/images/right-blue-arrow.svg');
	background-position: 0px 50%;
	color: #005ea8;
}

.container.content-container.no-columns .content-col {
	max-width: 1024px;
}

/*************** Right Sidebar ********************/
/*************************************************/
.right-side-col .box {
	margin-bottom: 2rem;
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.right-side-col .box p,
.right-side-col .box li,
.right-side-col .box td,
.right-side-col .box a,
.right-side-col .box span {
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.right-side-col .box p {
	color: #0a0406;
	font-size: 0.9rem;
	line-height: 1.4rem;
	font-weight: 400;
}

.right-side-col .box h4,
.right-side-col .box h3 {
	margin-top: 0.25rem;
	margin-bottom: 0.5rem;
	color: #0a0406;
	font-size: 1.143rem;
	line-height: 1.743rem;
	font-weight: 600;
}

.right-side-col .box h4:after,
.right-side-col .box h3:after {
	content: "";
	display: block;
	height: 3px;
	margin-top: 0.5rem;
	width: 30px;
	margin-bottom: 0.5rem;
	background-color: #e51837;
	background-image: none;
}

.right-side-col .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-side-col .box .contentbox_item_image {
	width: 24px;
}

.right-side-col .box.documents td:first-child {
	padding-right: 7px;
}

.right-side-col .box.links p {
	margin-bottom: 0.3rem;
}

.right-side-col .box.tools td:first-child img {
	margin-right: 5px;
}

.right-side-col td img {
	max-width: none;
}

@media screen and (max-width: 991px) {
	.right-side-col .box h4,
	.right-side-col .box h3 {
		margin-top: 1rem;
	}

	.right-side-col .box,
	.right-side-col .box p,
	.right-side-col .box li,
	.right-side-col .box td,
	.right-side-col .box a,
	.right-side-col .box span {
		font-size: 1rem;
		line-height: 1.7rem;
	}
}

/****************** Inside Page Styles ***************/
/*****************************************************/
iframe[src*="youtube.com"] {
	aspect-ratio: 16 / 9;
	height: auto;
	max-width: 100%;
}

.left-sidebar a,
.content-wrapper a,
.cboxes a {
	padding-bottom: 1px;
	border-bottom: 1px solid rgba(10, 4, 6, 0.12);
	color: #0a0406;
	line-height: 1.7rem;
	text-decoration: none;
}

.left-sidebar a:hover,
.content-wrapper a:hover,
.cboxes a:hover {
	border-bottom-color: transparent;
	color: #005ea8;
}

h1 {
	color: #0a0406 !important;
}

h2 {
	color: #0a0406;
}

h3 {
	color: #0a0406;
}

h4 {
	color: #0a0406;
}

h5 {
	color: #0a0406;
}

h6 {
	color: #0a0406;
}

.left-sidebar h1 a,
.main-content-section h1 a,
.right-sidebar h1 a,
.cboxes h1 a,
h1 a {
	padding-bottom: 3px;
	border-bottom: 1px solid rgba(10, 4, 6, 0.12);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #0a0406;
	text-decoration: none;
}

.left-sidebar h2 a,
.main-content-section h2 a,
.right-sidebar h2 a,
.cboxes h2 a,
h2 a {
	padding-bottom: 3px;
	border-bottom: 1px solid rgba(10, 4, 6, 0.12);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #0a0406;
	text-decoration: none;
}

.left-sidebar h3 a,
.main-content-col h3 a,
.right-nav-col h3 a,
.cboxes h3 a,
h3 a {
	padding-bottom: 3px;
	border-bottom: 1px solid rgba(10, 4, 6, 0.12);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #0a0406;
	text-decoration: none;
}

.left-sidebar h4 a,
.main-content-col h4 a,
.right-nav-col h4 a,
.cboxes h4 a,
h4 a {
	padding-bottom: 3px;
	border-bottom: 1px solid rgba(10, 4, 6, 0.12);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #0a0406;
	text-decoration: none;
}

.left-sidebar h5 a,
.main-content-col h5 a,
.right-nav-col h5 a,
.cboxes h5 a,
h5 a {
	padding-bottom: 3px;
	border-bottom: 1px solid rgba(10, 4, 6, 0.12);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #0a0406;
	text-decoration: none;
}

.left-sidebar h6 a,
.main-content-col h6 a,
.right-nav-col h6 a,
.cboxes h6 a,
h6 a {
	padding-bottom: 3px;
	border-bottom: 1px solid rgba(10, 4, 6, 0.12);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #0a0406;
	text-decoration: none;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
h1:hover a,
h2:hover a,
h3:hover a,
h4:hover a,
h5:hover a,
h6:hover a {
	padding-bottom: 3px;
	border-bottom-color: transparent;
	color: #005ea8;
}

/* buttons */
a.primary,
.inside-page-section .button,
.inside-page-section a.primary,
.homeboxes a.primary,
.primary,
.inside-page-section button[type="submit"],
.inside-page-section .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.ui-form-buttons .ui-form-button button,
.module-search button {
	display: inline-block;
	min-height: auto;
	min-width: 100px;
	margin-top: 0;
	margin-right: 0rem;
	margin-bottom: 0rem;
	padding: 14px 16px;
	border-radius: 2px;
	background-color: #005ea8 !important;
	color: #fff !important;
	font-weight: 600 !important;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	text-decoration: none;
	font-size: 1rem;
	line-height: 1rem;
	border: 0px;
	width: auto;
}

a.primary:hover,
.primary:hover,
.inside-page-section a.primary:hover .inside-page-section button[type="submit"]:hover,
.inside-page-section .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.homeboxes a.primary:hover,
.ui-form-buttons .ui-form-button button:hover,
.module-search button:hover {
	color: #fff !important;
	background-color: #0079d8 !important;
}

a.secondary,
.secondary,
.inside-page-section a.secondary,
.homeboxes a.secondary,
.inside-page-section button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-page-section .cms_form_button.secondary,
.inside-page-section .button.secondary {
	display: inline-block;
	min-height: auto;
	min-width: 100px;
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 1rem;
	padding: 14px 16px;
	border-radius: 2px;
	background-color: #003966 !important;
	color: #fff !important;
	font-weight: 600 !important;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	text-decoration: none;
	font-size: 1rem;
	line-height: 1rem;
	border: 0px;
	width: auto;
}

a.secondary:hover,
.secondary:hover,
.inside-page-section a.secondary:hover,
.inside-page-section button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-page-section .cms_form_button.secondary:hover,
.inside-page-section .button:hover,
.homeboxes a.secondary:hover {
	color: #fff !important;
	background-color: #0064b3 !important;
}

a.tertiary,
.tertiary,
.inside-page-section button[type="submit"].tertiary,
.cms_form_button.tertiary,
.inside-page-section a.tertiary,
.homeboxes a.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	display: inline-block;
	min-height: auto;
	min-width: 100px;
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 1rem;
	padding: 9px 16px;
	border-radius: 2px;
	background-color: #003966 !important;
	color: #fff !important;
	font-weight: 600 !important;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	text-decoration: none;
	font-size: 1rem;
	line-height: 1rem;
	border: 0px;
	width: auto;
}

a.tertiary:hover,
.tertiary:hover,
.inside-page-section a.tertiary:hover,
.inside-page-section button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover,
.homeboxes a.tertiary:hover {
	color: #fff !important;
	background-color: #0064b3 !important;
}

/* messages */
#message.success {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-left: 6px solid #11b91e;
	background-color: rgba(17, 185, 30, 0.25);
	font-size: 1.1rem;
}

#message.error {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-left: 6px solid #c70616;
	background-color: rgba(253, 54, 54, 0.15);
	font-size: 1.1rem;
}

#message.success p {
	font-weight: 500;
}

#message.error p {
	font-weight: 500;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	background-color: white;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid white;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: inherit;
	font-size: inherit;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #003966;
	color: white;
}

table.styled th {
	font-weight: inherit;
	font-size: inherit;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #005ea8;
	color: white;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
}

table.styled th a:hover {
	color: #fff;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
	color: black;
}

table.styled.striped tr:nth-child(even) {
	background: #e2e2e2;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.inside-page-section input[type="text"],
.inside-page-section input[type="password"],
.inside-page-section input[type="email"],
.inside-page-section select,
.inside-page-section textarea,
.inside-page-section input[type="search"] {
	max-width: 100%;
	vertical-align: middle;
	padding: 8px 12px;
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #666;
}

.inside-page-section textarea {
	resize: vertical;
}

.inside-page-section label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.inside-page-section input[type="text"],
	.inside-page-section input[type="password"],
	.inside-page-section input[type="email"],
	.inside-page-section select,
	.inside-page-section textarea,
	.inside-page-section input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	border-left-color: #e51837;
	background-color: rgba(229, 24, 55, 0);
	color: #0a0406;
}

blockquote p {
	font-weight: inherit;
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
}

.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	color: #333333;
	font-style: italic;
}

#pagination span {
	color: #333 !important;
}

/*********************** Footer **********************/
/*****************************************************/
.phone-desktop {
	display: inline-block;
}

.phone-mobile {
	display: none;
}

#GRIPFooterLogo {
	padding-top: 0px !important;
	line-height: 11px;
}

.footer-list-item-link.active {
	border-bottom: 1px solid #fff;
	color: hsla(0, 0%, 100%, 0.65);
}

@media (max-width: 991px) {
	.phone-desktop {
		display: none;
	}

	.phone-mobile {
		display: inline-block;
	}

	#GRIPFooterLogo {
		margin-bottom: 1rem;
		margin-top: 1.5rem;
	}
}

@media (max-width: 767px) {
	#GRIPFooterLogo {
		margin-left: auto;
		margin-right: auto;
	}
}

/*********************** Modules ********************/
/*****************************************************/
/* events */
#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_list .cms_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

#events_module.cms_form h3 {
	margin-bottom: 0px;
}

#events_module.cms_form .cms_divider {
	margin-top: 5px !important;
	padding-top: 1px !important;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/* files */
.collection--files .node--file,
.collection--categories .node--category,
.collection.collection--files {
	margin: 1.5rem 0px 0px 0px;
	padding: 1.5rem 0px 0px 0px;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

.collection--files .node--file p,
.collection--categories .node--category p,
.collection.collection--files p {
	margin-bottom: 0px;
	margin-top: 0px;
}

.collection.collection--files .bookmark.create {
	background-color: transparent;
	color: #6b7571;
	font-weight: 400;
}

.collection.collection--files .field--file--title a,
.collection.collection--categories .field--category--title a {
	padding-left: 0px;
	margin-left: 0px;
}

.collection.collection--files .field--file--title,
.collection.collection--files .field--category--title {
	background-position: 0 5px;
}

.collection.collection--categories .field--category--title {
	background-position: 0px 5px;
}

.right-side-col .box.filter .filter-row .select2-container,
.right-side-col .box.filter .filter-row #filter-keywords {
	max-width: 100%;
	width: 100% !important;
}

.right-side-col .box.filter .filter-row.ui-form-buttons {
	padding: 0px;
}

.right-side-col .box.filter .filter-row.ui-form-buttons .ui-form-field {
	padding: 6px;
	text-align: left;
}

.right-side-col .box.filter .filter-row .select2-container-multi .select2-choices,
.right-side-col .box.filter .filter-row .select2-container .select2-choice {
	border-radius: 0px;
	box-shadow: none;
	border: 1px solid #666666;
	background: none;
	padding: 8px 12px;
	height: auto;
}

.right-side-col .box.filter .filter-row .select2-container .select2-choice > .select2-chosen {
	line-height: 1.2rem;
}

.right-side-col .box.filter .filter-row .select2-container-multi .select2-choices .select2-search-field .select2-input {
	margin: 0;
	padding: 0px;
}

.right-side-col .box.filter .filter-row .select2-container-multi .select2-choices .select2-search-field input {
	border: 1px solid #fff;
	outline: 1px solid #fff !important;
}

.right-side-col .box.filter .filter-row .select2-container-multi .select2-choices .select2-search-field input:focus {
	border: 1px solid #fff;
	outline: 1px solid #fff !important;
}

/* faq */
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_item_divider,
#faqs_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

/* locations */
#locations_module.list .locations_list_title,
#locations_module.list .location_types_title {
	border-color: rgba(92, 92, 92, 0.15);
}

#locations_module.list .type_item .title,
#locations_module.list .type_item .title a {
	font-size: 1.5rem;
}

#locations_module.list ul.locations-list-items li {
	overflow: hidden;
}

/* news */
#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

#news_module.cms_list .cms_list_item .image img {
	max-width: none;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}
}

/* partners */
#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 25px;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

#partners_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* resources */
.right-side-col .box .moduleDocuments-rss-feed a {
	border-bottom: 0px;
}

/* staff */
#staffdirectory_module.cms_list .cms_list_item .image {
	padding-right: 15px;
	width: 150px;
}

#staffdirectory_module.cms_list .cms_list_item .image a img {
	max-width: 140px;
}

#staffdirectory_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		float: none;
		margin-right: 0px;
		display: block;
	}

	#staffdirectory_module.cms_list .cms_list_item {
		display: block;
	}

	#staffdirectory_module.cms_list .cms_list_item .info {
		width: 100%;
	}
}

/* submission forms */
#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 2px;
	font-weight: normal;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

/* testimonilas */
#testimonials_module.cms_list .cms_list_item .cms_bottom,
#testimonials_module.cms_list .cms_list_item .cms_content {
	display: inline-block;
	width: 100%;
}

#testimonials_module.cms_list .cms_item_divider,
#testimonials_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

/* accounts */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 243px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	margin: 20px 0;
	padding-bottom: 4px;
	color: #333333;
	font-weight: bold;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url('/core/public/shared/assets/images/required.gif');
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/******************* Content Boxes *******************/
/****************************************************/
/* events */
#events_module.homepage_contentbox {
	margin-bottom: 1.5rem;
}

#events_module.homepage_contentbox .event_list table tr td.date {
	width: 100px;
}

#events_module.homepage_contentbox .event_list table tr td.title {
	width: calc(100% - 100px);
	text-align: left;
}

/* news */
#news_module.homepage_contentbox .cms_title h3 {
	margin-bottom: 0px;
}

#news_module.homepage_contentbox .cms_date h3 {
	margin-top: 6px;
	font-size: 1.2rem;
	line-height: 1.3rem;
}

/* partners */
#partners_module.homepage_contentbox.displaymode_list .footer {
	float: left;
	padding-top: 10px;
}

/* form */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	line-height: 1.5rem;
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

/* grid styles */
.grid {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: -1%;
	margin-bottom: 20px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.grid-box {
	margin-right: 1%;
	margin-bottom: 15px;
	padding: 20px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.grid-box.half {
	width: 49%;
}

.grid-box.third {
	width: 32.33%;
}

.grid-box.quarter {
	width: 24%;
}

.grid-box.fifth {
	width: 19%;
}

.grid-box.twothirds {
	width: 65.66%;
}

.grid-box.center {
	text-align: center;
}

.grid-box.center p {
	margin: 0 auto;
	max-width: 400px;
}

@media (max-width: 991px) {
	.grid-box.quarter {
		width: 49%;
	}

	.grid-box.fifth {
		width: 49%;
	}
}

@media (max-width: 767px) {
	.grid-box.half {
		width: 100%;
	}

	.grid {
		margin-left: -20px;
		margin-right: calc(-20px + 1%);
	}

	.grid-box.twothirds {
		width: 100%;
	}

	.grid-box.third {
		width: 100%;
	}

	.grid-box-1 {
		order: 2;
	}

	.grid-box-2 {
		order: 1;
	}
}

@media (max-width: 630px) {
	.grid-box.third {
		order: -1;
	}
}

@media (max-width: 479px) {
	.grid-box {
		margin-right: 0%;
	}

	.grid-box.half {
		width: 100%;
	}

	.grid-box.third {
		width: 100%;
	}

	.grid-box.quarter {
		width: 100%;
	}

	.grid-box.fifth {
		width: 100%;
	}
}
